import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet, HelmetProvider } from "react-helmet-async";
import config from "../../../config";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const textColor = process.env.REACT_APP_TEXT_COLOR;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const textHighlight = process.env.REACT_APP_TEXT_HIGHLIGHT;
const restaurantURL = process.env.REACT_APP_SITE;
const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

export const OrderSlider = () => {
  useEffect(() => {
    AOS.init({
      duration: 600,
    });
  }, []);

  const [orderContent, setorderContent] = useState("");
  const [introMessage, setIntroMessage] = useState([]);

  useEffect(() => {
    axios
      .get(`${apiBaseURL}/site/get-intro-message/${serverId}/${siteId}`)
      .then((response) => {
        setIntroMessage(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    const orderContentText = config.ordercontent;
    setorderContent(orderContentText);
  }, []);

  return (
    <div
      className="OrderSlider"
      data-aos-easing="ease-in-out"
      data-aos-delay="0"
      // data-aos="fade-top"
    >
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{"Order Now: Browse, view, and track your order."}</title>
          <link rel="canonical" href={`${restaurantURL}menu`} />
          {/* This should be dynamic */}
          <meta name="description" content={`${orderContent.description}`} />
          <meta name="keywords" content={`${orderContent.keywords}`} />
          <meta
            property="og:title"
            content={`${restaurantName}: Browse, view, and track your order`}
          />
          <meta
            property="og:description"
            content={`${orderContent.description}`}
          />
        </Helmet>
      </HelmetProvider>
      <div
        className="OrderHeader"
        style={{
          backgroundImage: `url(${siteMedSrc}order-now-bg.webp)`,
          color: textColor,
          height: "auto",
        }}
      >
        <Container>
          <Row>
            <col className="whitespace"></col>
            {siteId == 245 ? (
              <>
                <Row>
                  <Col sm={12} lg={8}>
                    <h1
                      className="left"
                      style={{ color: textHighlight, visibility: "hidden" }}
                    >
                      {introMessage && introMessage.Title}
                    </h1>
                    <p
                      className="white title"
                      style={{ fontSize: "large", visibility: "hidden" }}
                    >
                      {introMessage && introMessage.Message}
                    </p>
                  </Col>
                </Row>
              </>
            ) : (
              <Row>
                <Col sm={12} lg={8}>
                  <h1 className="left" style={{ color: textHighlight }}>
                    {introMessage && introMessage.Title}
                  </h1>
                  <p className="white title" style={{ fontSize: "large" }}>
                    {introMessage && introMessage.Message}
                  </p>
                </Col>
              </Row>
            )}
            <Col sm={0} lg={4}></Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
