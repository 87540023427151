import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const buttonColor = process.env.REACT_APP_BUTTON_COLOR;
const textColor = process.env.REACT_APP_TEXT_COLOR;
const textHighlight = process.env.REACT_APP_TEXT_HIGHLIGHT;

const SpecialDay = ({ day, meals }) => (
  <Accordion sx={{ backgroundColor: buttonColor, margin: "10px auto" }}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} id={`${day}-header`}>
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        {day}
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      {meals.map((meal, index) => (
        <Typography key={index} variant="h6" sx={{ fontWeight: 900 }}>
          <strong style={{ color: textColor }}>{meal.label}:</strong>{" "}
          {meal.text}
        </Typography>
      ))}
    </AccordionDetails>
  </Accordion>
);

const Specials = () => {
  const dailySpecials = [
    {
      day: "Renowned Dining",
      meals: [
        {
          label: "Signature Meals",
          text: "Signature broasted chicken, homemade pizzas, Friday night fish fries, and Sunday family-style chicken dinners.",
        },
      ],
    },
    {
      day: "Weekly Specials",
      meals: [
        {
          label: "Special Offers",
          text: "Affordable chicken plates on Wednesdays and special meal deals on weekends.",
        },
      ],
    },
    {
      day: "Event Hosting",
      meals: [
        {
          label: "Banquet Services",
          text: "Spacious banquet hall available for private events, parties, and banquets.",
        },
      ],
    },
    {
      day: "Live Entertainment",
      meals: [
        {
          label: "Interactive Shows",
          text: "Hosting dueling pianos, professional wrestling, and other interactive shows.",
        },
      ],
    },
    {
      day: "Family Fun",
      meals: [
        {
          label: "Family Activities",
          text: "On-site bowling alley and birthday packages for kids and families.",
        },
      ],
    },
    {
      day: "Community Events",
      meals: [
        {
          label: "Social Leagues",
          text: "Cornhole leagues for competitive and social players.",
        },
      ],
    },
    {
      day: "Great Atmosphere",
      meals: [
        {
          label: "Welcoming Environment",
          text: "Old-school charm with friendly staff and exceptional service.",
        },
      ],
    },
    {
      day: "Convenient Location",
      meals: [
        {
          label: "Easy Access",
          text: "Situated at E896 County Rd N, Luxemburg, WI, with wheelchair accessibility.",
        },
      ],
    },
    {
      day: "Full-Service Bar",
      meals: [
        {
          label: "Drink Specials",
          text: "Featuring draft beer, cocktails, and happy hour specials.",
        },
      ],
    },
    {
      day: "Positive Reputation",
      meals: [
        {
          label: "Customer Praise",
          text: "Highly rated for food quality, friendly staff, and memorable experiences.",
        },
      ],
    },
  ];

  return (
    <Box
      sx={{
        padding: "20px",
        color: "#fff",
        textShadow:
          "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
        margin: "0 auto",
        maxWidth: "80%",
        textAlign: "center",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          color: textHighlight,
          textShadow:
            "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
        }}
      >
        <strong>Business Highlights</strong>
      </Typography>
      <Typography variant="body1" sx={{ fontSize: "1.25em", fontWeight: 900 }}>
        We look forward to welcoming you soon! 🎉
      </Typography>
      {dailySpecials.map((special, index) => (
        <SpecialDay key={index} day={special.day} meals={special.meals} />
      ))}
    </Box>
  );
};

export default Specials;
