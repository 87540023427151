import React, { useEffect, useState, useRef, useMemo, Suspense } from "react";
import {
  Typography,
  Grid,
  Container,
  Button,
  Box,
  Toolbar,
  IconButton,
} from "@material-ui/core";
import AppBar from "@mui/material/AppBar";
import { Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { numberWithCommas } from "../../../utils/numbersWithComma";
import moment from "moment";
import { Form, Input } from "../../../components";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { setTip } from "../../../redux/cartReducer/actions";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "rc-time-picker/assets/index.css";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import {
  setSelectedServiceOption,
  setOrderProcessingDate,
  setSelectedAddress,
} from "../../../redux/publicReducer/actions";
import { useHistory } from "react-router-dom";
import {
  setModeOfPayment,
  setPromoCode,
  setTotalDiscount,
} from "../../../redux/cartReducer/actions";
import { useCookies } from "react-cookie";
import DrawerRight from "features/eComm/components/DrawerRight";

import BottomCheckOutNav from "features/eComm/components/BottomCheckOutNav";
import BlankSpace from "features/eComm/components/BlankSpace";

import TextField from "@mui/material/TextField";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

import ScrollToDown from "components/scroll/ScrollToDown";
import ScrollToUp from "components/scroll/ScrollToUp";
import Fab from "@mui/material/Fab";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import FontSizeChanger from "react-font-size-changer";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Helmet, HelmetProvider } from "react-helmet-async";
import BarTop from "features/eComm/components/BarTop";
import BarBottom from "features/eComm/components/BarBottom";
import config from "../../../config";
import SelectCreditCardDrawerSL from "./SelectCreditCardDrawerSL";
import PaymentSelectorSL from "./PaymentSelectorSL";
import ServiceOptionsDrawerSL from "./ServiceOptionsDrawerSL";
import TipsDrawerSL from "./TipsDrawerSL";
import axios from "axios";

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const siteImgSrc = process.env.REACT_APP_IMAGE_SRC;
const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const restaurantURL = process.env.REACT_APP_SITE;

const serviceCharge = parseFloat(process.env.REACT_APP_SERVICE_CHARGE);
const tableNumber = sessionStorage.getItem("table");

const usePoints = process.env.REACT_APP_USE_POINTS;
const enableRevenueCenter = process.env.REACT_APP_ENABLE_REVENUE_CENTER;
const enableTip = process.env.REACT_APP_ENABLE_TIP;
const enableKiosk = process.env.REACT_APP_ENABLE_EKIOSK;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;
const buttonText = process.env.REACT_APP_BUTTON_TEXT;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
let enableNewUI = process.env.REACT_APP_NEWUI_LW;

//--Time Conditions--//
const endHour = 20;
const endMinutes = 0;

export const CheckoutSL = (props) => {
  const {
    orderProcessingDate,
    orderProcessingType,
    selectedAddress,
    user,
    loading,
    orderTimeSelectValue,
  } = useSelector((state) => state.public);
  const [siteSettings, setSiteSettings] = useState([]);
  const [openTips, setOpenTips] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [setDiscountPercentage] = useState(0);
  const [setTax] = useState();
  const history = useHistory();
  const [openServiceOptions, setOpenServiceOptions] = useState(false);
  const [openUserCards, setOpenUserCards] = useState(false);
  const [tempPromoCode, setTempPromoCode] = useState("");
  const [pickupTimebyRevenueCenter, setPickupTimebyRevenueCenter] = useState(
    []
  );
  const [cookiePayment, setCookiePayment, removeCookiePayment] = useCookies([
    "payment",
  ]);
  const [selectedTime, setSelectedTime] = useState({
    label: "",
    value: "",
  });
  const [updateTime, setUpdateTime] = useState("");
  const [pickupTimeLimits, setPickupTimeLimits] = useState();
  const [availableOrderTypes, setAvailableOrderTypes] = useState();

  const [checkoutContent, setcheckoutContent] = useState("");

  useEffect(() => {
    const checkoutContentText = config.checkoutcontent;

    setcheckoutContent(checkoutContentText);
  }, []);

  //onsreen keyboard
  const [keyboardVisible, setKeyboardVisible] = useState(false);
  const [keyboardVisibleName, setKeyboardVisibleName] = useState(false);
  const [messageToKitchen, setMessageToKitchen] = useState(
    sessionStorage.getItem("instuctions") || ""
  );
  const [name, setName] = useState(sessionStorage.getItem("name") || "");
  const [layoutName, setLayoutName] = useState("default");
  const enternameRef = useRef(null);
  const messageRef = useRef(null);
  const [caretPosition, setCaretPosition] = useState(null);

  // Function to handle clicks outside the TextField and Keyboard
  const handleClickOutside = (event) => {
    if (enternameRef.current && !enternameRef.current.contains(event.target)) {
      setKeyboardVisibleName(false);
    }
    if (messageRef.current && !messageRef.current.contains(event.target)) {
      setKeyboardVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Update caret position
  const updateCaretPosition = (ref) => {
    const input = ref.current.querySelector("input");
    if (input) {
      setCaretPosition(input.selectionStart);
    }
  };

  // Handle key press for the message input
  const handleOnKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}") {
      handleShift();
    } else if (button === "{enter}") {
      setKeyboardVisible(false);
    } else if (button === "{bksp}") {
      if (caretPosition > 0) {
        setMessageToKitchen((prev) => {
          const newValue =
            prev.slice(0, caretPosition - 1) + prev.slice(caretPosition);
          setCaretPosition(caretPosition - 1);
          return newValue;
        });
      }
    } else if (button === "{space}") {
      setMessageToKitchen((prev) => {
        const newValue =
          prev.slice(0, caretPosition) + " " + prev.slice(caretPosition); // Add a blank space
        setCaretPosition(caretPosition + 1);
        return newValue;
      });
    } else {
      setMessageToKitchen((prev) => {
        const newValue =
          prev.slice(0, caretPosition) + button + prev.slice(caretPosition);
        setCaretPosition(caretPosition + 1);
        return newValue;
      });
    }
  };

  // Handle change for the message input
  const handleOnChange = (value) => {
    setMessageToKitchen(value);
    sessionStorage.setItem("instuctions", value);
  };

  // Handle key press for the name input
  const handleOnKeyPressName = (button) => {
    if (button === "{shift}" || button === "{lock}") {
      handleShift();
    } else if (button === "{enter}") {
      setKeyboardVisibleName(false);
    } else if (button === "{bksp}") {
      if (caretPosition > 0) {
        setName((prev) => {
          const newValue =
            prev.slice(0, caretPosition - 1) + prev.slice(caretPosition);
          setCaretPosition(caretPosition - 1);
          return newValue;
        });
      }
    } else if (button === "{space}") {
      setName((prev) => {
        const newValue =
          prev.slice(0, caretPosition) + " " + prev.slice(caretPosition); // Add a blank space
        setCaretPosition(caretPosition + 1);
        return newValue;
      });
    } else {
      setName((prev) => {
        const newValue =
          prev.slice(0, caretPosition) + button + prev.slice(caretPosition);
        setCaretPosition(caretPosition + 1);
        return newValue;
      });
    }
  };

  // Handle shift key press
  const handleShift = () => {
    setLayoutName(layoutName === "default" ? "shift" : "default");
  };

  // Handle change for the name input
  const handleOnChangeName = (value) => {
    setName(value);
    sessionStorage.setItem("name", value);
  };

  const Swal = require("sweetalert2");

  const {
    cartItems,
    totalPriceItems,
    tips,
    selectedPayment,
    promoCode,
    orderSession,
    totalDiscount,
  } = useSelector((state) => state.cart);

  const dispatch = useDispatch();

  const initialValues = {
    message: "",
  };

  const validationSchema = Yup.object({
    message: Yup.string(),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { watch, setValue } = methods;
  const watchMessage = watch("message");
  const handleSubmit = (d) => {
    // console.log(d);
  };

  // =========== START ==========
  // console.log(props.location, "Loki");
  // =========== END ===========

  // =========== START ==========
  // =========== END ===========

  // Get cookie
  const getCookie = (key) => {
    var keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
    return keyValue ? keyValue[2] : null;
  };

  // Set kitchen message to sessionStorage
  const setKitchenMsg = (e) => {
    sessionStorage.setItem("instuctions", e.target.value);
  };

  const updatePickupTimebyRevenueCenter = (pickup) => {
    if (enableRevenueCenter == 1) {
      console.log(pickup, "pickup - updatePickupTimebyRevenueCenter");
      setPickupTimebyRevenueCenter(pickup);
    }
  };

  const applyCoupon = () => {
    dispatch(setPromoCode(tempPromoCode));
    setDiscount(0);
    setDiscountPercentage(0);

    const url =
      apiBaseURL +
      "/payment/coupon/" +
      serverId +
      "/" +
      siteId +
      "/" +
      tempPromoCode;
    //const url = "/servingintel_api/public/items/all";
    axios
      .get(url)
      .then((response) => {
        if (response.data.length == 0) {
          dispatch(setPromoCode(""));
          alert("Invalid Coupon");
        } else {
          const dept = response.data.department;
          const percent = response.data.discount_percent;
          const discount = response.data.discount;
          const subTotal = response.data.subTotal;

          setDiscountPercentage(parseFloat(percent * 100));

          if (dept !== "") {
            //by deparment
            let totalDiscountPrice = 0;
            cartItems.forEach((item) => {
              if (item.Department == dept) {
                totalDiscountPrice += parseFloat(
                  parseFloat(item.DefaultPrice) * item.quantity
                );
              }
            });

            if (totalDiscountPrice > 0) {
              //applicable items
              if (discount > 0) {
                // direct amount discount
                if (totalPriceItems >= subTotal) {
                  setDiscount(parseFloat(discount));
                } else {
                  dispatch(setPromoCode(""));
                  alert(
                    "Total item(s) price did not meet the minimum required for this coupon which is $" +
                      subTotal +
                      "."
                  );
                }
              } else {
                //percentage discount
                if (totalPriceItems >= subTotal) {
                  setDiscount(parseFloat(totalDiscountPrice * percent));
                } else {
                  dispatch(setPromoCode(""));
                  alert(
                    "Total item(s) price did not meet the minimum required for this coupon which is $" +
                      subTotal +
                      "."
                  );
                }
              }
            } else {
              dispatch(setPromoCode(""));
              alert("Coupon is not applicable to the existing Check Item(s).");
            }
          } else {
            //all items
            setDiscount(parseFloat(totalPriceItems * percent));
          }
        }
      })
      .catch((error) => {});
  };

  // Update item quantity
  const updateQuantity = (item, change) => {
    const newCartItems = cartItems
      .map((cartItem) => {
        if (cartItem.ItemID === item.ItemID) {
          const newQuantity = cartItem.quantity + change;
          return { ...cartItem, quantity: newQuantity };
        }
        return cartItem;
      })
      .filter((cartItem) => cartItem.quantity > 0);

    dispatch({
      type: "SET_CART",
      payload: newCartItems,
    });
  };

  // Keep this function just in case it is needed in the future
  const getDiscountRate = (item) => {
    const discountRate =
      (parseFloat(item.ActualPrice) / parseFloat(item.DefaultPrice)) * 100;
    const customerDiscount = parseFloat(
      sessionStorage.getItem("customerDiscount")
    );

    return discountRate;
  };

  const getSubtotalPrice = () => {
    if (totalPriceItems > 0) {
      return parseFloat(totalPriceItems);
    }

    const subtotalPrice = cartItems.reduce((subtotal, item) => {
      const isNumber = typeof item.DefaultPrice === "number";
      const itemPrice = isNumber
        ? item.DefaultPrice / 100
        : parseFloat(item.DefaultPrice) || 0; // Ensure a valid itemPrice
      const quantity = item.quantity || 1; // Default to 1 if quantity is missing
      return subtotal + itemPrice * quantity;
    }, 0);

    return parseFloat(subtotalPrice);
  };

  const getTotalTaxAmount = () => {
    const totalTaxAmount = cartItems.reduce((total, item) => {
      const tax = parseFloat(item.TaxTotal) || 0; // Default to 0 if TaxTotal is missing or invalid
      const quantity = item.quantity || 1; // Default to 1 if quantity is missing or invalid
      return total + tax * quantity;
    }, 0);

    return totalTaxAmount;
  };

  const getTotalDiscount = () => {
    const totalItemDiscount = cartItems.reduce((total, item) => {
      const itemDiscount = parseFloat(item.DiscountTotal) || 0; // Default to 0 if TaxTotal is missing or invalid
      const quantity = item.quantity || 1; // Default to 1 if quantity is missing or invalid
      return total + itemDiscount * quantity;
    }, 0);
    // totalItemDiscount is for each ITEM DISCOUNT
    // totalDiscount (from Redux) is for PROMO/COUPON DISCOUNT
    return totalItemDiscount + totalDiscount;
  };

  const getTotalPrice = () => {
    return (
      getSubtotalPrice() +
      tips +
      getTotalTaxAmount() +
      serviceCharge -
      getTotalDiscount()
    );
  };

  // Set update time
  useEffect(() => {
    var sourceUpdateTime = new EventSource(
      "https://ecommv2.servingintel.com/Items/checkUpdate?site_id=" + siteId
    );
    sourceUpdateTime.onmessage = function (event) {
      var u_items = JSON.parse(event.data);
      //console.log(u_items[0].utime);
      if (u_items[0].time != "") {
        if (sessionStorage.getItem("update_date_time") !== u_items[0].time) {
          setUpdateTime(u_items[0].time);
          sessionStorage.setItem("update_date_time", u_items[0].time);
        }
      }
    };

    //Set default serviceOption
    // if (enalbeDelivery == 1 && orderProcessingType === 2) {
    //   dispatch(setSelectedServiceOption(2));
    // }
    // if (enablePickup == 1 && orderProcessingType === 1) {
    //   dispatch(setSelectedServiceOption(1));
    // }
    // if (enableDineIN == 1 && orderProcessingType === 0) {
    //   dispatch(setSelectedServiceOption(0));
    // }
  }, []);

  // Check if store is open
  useEffect(() => {
    const url =
      apiBaseURL + "/site/get-site-settings/" + serverId + "/" + siteId;
    axios
      .get(url)
      .then((response) => {
        setSiteSettings(response.data);
        if (response.data.OnOff < 1) {
          Swal.fire({
            title: "Store Message",
            text: response.data.eCommerceDisplayMessage,
            icon: "info",
            confirmButtonText: "OK",
          });
          history.push("/welcome");
        }
      })
      .catch((error) => {});
  }, [updateTime]);

  // Checkout buffer
  useEffect(() => {
    axios
      .get(`${apiBaseURL}/site/is-checkout-disabled/${serverId}/${siteId}`)
      .then((response) => {
        if (response.data === 1) {
          Swal.fire({
            title: "Store Message",
            text: "Checkout is disabled at this moment.",
            icon: "info",
            confirmButtonText: "OK",
          });
          history.push("/");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [updateTime]);

  // Check for pinCodeOk in sessionStorage
  useEffect(() => {
    if (
      !sessionStorage.getItem("pinCodeOk") &&
      sessionStorage.getItem("pinCodeOk") !== 1
    ) {
      window.location.href = "/";
    }
  }, []);

  // Set date as current date
  useEffect(() => {
    let date = "";
    if (!orderProcessingDate) {
      date = moment();
    }

    const d = new Date();
    const hour = d.getHours(); //get current hour
    const min = d.getMinutes(); //get current min
    const cdate = d.getDate();
    const cyear = d.getFullYear();
    const cmonth = d.getMonth();

    if (
      d.getDate() == cdate &&
      d.getMonth() == cmonth &&
      d.getFullYear() == cyear &&
      (hour > endHour || (hour == endHour && min >= endMinutes))
    ) {
      //alert("Store is closed. You can order for next day");
      date = moment(d, "MM-DD-YYYY").add(1, "days");
    }

    if (!orderProcessingDate) {
      dispatch(setOrderProcessingDate(date));
    }
  }, []);

  // Set cookie payment
  useEffect(() => {
    // console.log("pc:" + promoCode);
    if (cookiePayment?.payment?.id) {
      dispatch(setModeOfPayment(cookiePayment.payment));
    }
    if (cookiePayment?.tips) {
      dispatch(setTip(cookiePayment.tips));
    }
    if (cookiePayment.message) {
      setValue("message", cookiePayment.message);
    }
    if (cookiePayment.promoCode) {
      dispatch(setPromoCode(cookiePayment.promoCode));
      setTempPromoCode(cookiePayment.promoCode);
    }
  }, []);

  // Set sic_address value from cookie to selectedAddress variable in Redux
  useEffect(() => {
    // if (user?.address) {
    //   dispatch(setSelectedAddress(user.address));
    // }
    const address = getCookie("sic_address");
    if (address) {
      dispatch(setSelectedAddress(JSON.parse(address)));
    }
  }, []);

  // setCookiePayment and removeCookiePayment
  useEffect(() => {
    if (selectedPayment) {
      setCookiePayment("payment", selectedPayment, { path: "/" });
    } else {
      removeCookiePayment("payment", { path: "/" });
    }
    if (tips) {
      setCookiePayment("tips", tips, { path: "/" });
    } else {
      removeCookiePayment("tips", { path: "/" });
    }

    if (promoCode) {
      setCookiePayment("promoCode", promoCode, { path: "/" });
    } else {
      removeCookiePayment("promoCode", { path: "/" });
    }

    if (watchMessage) {
      setCookiePayment("message", watchMessage, { path: "/" });
    } else {
      removeCookiePayment("message", { path: "/" });
    }
  }, [selectedPayment, tips, watchMessage, promoCode]);

  // promoCode, discount, discountPercentage
  useEffect(() => {
    if (tempPromoCode || promoCode) {
      setDiscount(0);
      setDiscountPercentage(0);
      const tpc =
        tempPromoCode == ""
          ? promoCode != ""
            ? promoCode
            : "coupon"
          : promoCode;
      const url =
        apiBaseURL + "/payment/coupon/" + serverId + "/" + siteId + "/" + tpc;
      //const url = "/servingintel_api/public/items/all";
      axios
        .get(url)
        .then((response) => {
          if (response.data.length == 0) {
            dispatch(setPromoCode(""));
            //alert("Invalid Coupon");
          } else {
            const dept = response.data.department;
            const percent = response.data.discount_percent;
            const discount = response.data.discount;
            const subTotal = response.data.subTotal;

            setDiscountPercentage(parseFloat(percent * 100));

            if (dept !== "") {
              //by deparment
              let totalDiscountPrice = 0;
              cartItems.forEach((item) => {
                if (item.Department == dept) {
                  totalDiscountPrice += parseFloat(
                    parseFloat(item.DefaultPrice) * item.quantity
                  );
                }
              });

              if (totalDiscountPrice > 0) {
                //applicable items
                if (discount > 0) {
                  // direct amount discount
                  if (totalPriceItems >= subTotal) {
                    setDiscount(parseFloat(discount));
                  } else {
                    //dispatch(setPromoCode(""));
                    //alert("Total item(s) price did not meet the minimum required for this coupon which is $"+subTotal+".")
                  }
                } else {
                  //percentage discount
                  if (totalPriceItems >= subTotal) {
                    setDiscount(parseFloat(totalDiscountPrice * percent));
                  } else {
                    //dispatch(setPromoCode(""));
                    //alert("Total item(s) price did not meet the minimum required for this coupon which is $"+subTotal+".")
                  }
                }
              } else {
                dispatch(setPromoCode(""));
                //alert("Coupon is not applicable to the existing Check Item(s).")
              }
            } else {
              //all items
              setDiscount(parseFloat(totalPriceItems * percent));
            }
          }
        })
        .catch((error) => {});
    }
  }, [totalPriceItems]);

  // Save promo/coupon discount to setTotalDiscount Redux
  useEffect(() => {
    dispatch(setTotalDiscount(discount));
  }, [discount]);

  // Get pickup limit settings
  useEffect(() => {
    const menuName = props.location.state && props.location.state.menuName;

    const currentMenuName = menuName != "" ? menuName : "none";
    const url =
      apiBaseURL +
      "/items/pickup-time-limit/" +
      serverId +
      "/" +
      siteId +
      "/" +
      currentMenuName +
      "/" +
      orderProcessingType;

    axios
      .get(url)
      .then((response) => {
        setPickupTimeLimits(response.data);
      })
      .catch((error) => {});
  }, [orderProcessingType]);

  // Get available order types
  useEffect(() => {
    const revenueCenter =
      props.location.state && props.location.state.revenueCenter
        ? props.location.state.revenueCenter
        : "none";
    const menuName =
      props.location.state && props.location.state.menuName
        ? props.location.state.menuName
        : "none";
    const currentMenuName = menuName != "" ? menuName : "none";

    const url =
      apiBaseURL +
      "/items/available-order-types/" +
      serverId +
      "/" +
      siteId +
      "/" +
      revenueCenter +
      "/" +
      currentMenuName;
    axios
      .get(url)
      .then((response) => {
        if (response.data) {
          let orderTypes = [];

          // Map over response.data and push OrderType to orderTypes array
          console.log(response.data, "data");
          response.data.forEach((item) => orderTypes.push(item.OrderType));

          // Create a Set from orderTypes to remove duplicates and convert it back to an array
          const uniqueOrderTypes = Array.from(new Set(orderTypes));
          // Set the state with the array of unique order types
          setAvailableOrderTypes(uniqueOrderTypes);

          if (orderProcessingType > 0) return;
          if (uniqueOrderTypes.includes("Dine In")) {
            dispatch(setSelectedServiceOption(0));
          } else if (uniqueOrderTypes.includes("Pick Up")) {
            dispatch(setSelectedServiceOption(1));
          } else {
            dispatch(setSelectedServiceOption(2));
          }
        }
      })
      .catch((error) => {});
  }, []); // run this once, upon component mount

  // Previously retrieved TAX from TAX TABLE (now deprecated but kept for reference)
  // useEffect(() => {
  //   if (parseInt(enableZeroTax) === 1 && enableSL === 1) {
  //     setTax(0);
  //   } else {
  //     const url =
  //       apiBaseURL + "/items/tax/" + siteId + "?server_id=" + serverId;
  //     axios
  //       .get(url)
  //       .then((response) => {
  //         setTax(
  //           parseFloat(parseFloat(response.data[0].TaxPercent / 100) / 100) /
  //             100
  //         );
  //       })
  //       .catch((error) => {});
  //   }
  // }, []);

  // console.log(
  //   orderProcessingDate,
  //   orderProcessingType,
  //   orderTimeSelectValue,
  //   orderProcessingDate && orderTimeSelectValue
  // );
  return (
    <React.Fragment>
      <div
        className={enableKiosk === "0" ? "HeaderImg" : "HeaderImgeKiosk"}
        style={{
          position: "relative",
          backgroundImage: `url(${siteMedSrc}${
            enableKiosk === "0" ? "welcome-banner.webp" : "background.webp"
          })`,
          color: buttonText,
          height: enableKiosk === "0" ? "250px" : "auto",
          backgroundAttachment: enableKiosk === "0" ? "local" : "scroll",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1,
          }}
        />
        <Container style={{ position: "relative", zIndex: 2 }}>
          <Row>
            <Col xs={12}>
              <h2
                style={{
                  color: buttonText,
                }}
                className={
                  enableKiosk === "0"
                    ? "padding-top-20 blankspace center"
                    : "padding-bottom-20 center"
                }
              >
                Your Payment and Billing Details
              </h2>
            </Col>
          </Row>
        </Container>
      </div>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`${restaurantName}: ${checkoutContent?.title}`}</title>
          <link rel="canonical" href={`${restaurantURL}checkout`} />
          <meta
            name="description"
            content={`${checkoutContent?.description}`}
          />
          <meta name="keywords" content={`${checkoutContent?.keywords}`} />
          <meta
            property="og:title"
            content={`${restaurantName}: ${checkoutContent?.title}`}
          />
          <meta
            property="og:description"
            content={`${checkoutContent?.description}`}
          />
          <meta
            property="twitter:title"
            content={`${restaurantName}: ${checkoutContent?.title}`}
          />
          <meta
            property="twitter:description"
            content={`${checkoutContent?.description}`}
          />
        </Helmet>
      </HelmetProvider>
      {parseInt(enableKiosk) === 0 && (
        <FontSizeChanger
          targets={["#target .content"]}
          onChange={(element, newValue, oldValue) => {
            console.log(element, newValue, oldValue);
          }}
          options={{
            stepSize: 2,
            range: 5,
          }}
          customButtons={{
            up: (
              <AppBar
                position="fixed"
                sx={{
                  width: "95px",
                  boxShadow: 0,
                  top: "auto",
                  bottom: 237.5,
                  backgroundColor: "transparent",
                  marginBottom: "50px",
                }}
              >
                <Toolbar>
                  <Box sx={{ flexGrow: 1 }} />
                  <IconButton>
                    <Fab
                      color="primary"
                      aria-hidden="true"
                      sx={{ backgroundColor: "#367c2b" }}
                    >
                      <AddCircleOutlineIcon />
                    </Fab>
                  </IconButton>
                </Toolbar>
              </AppBar>
            ),
            down: (
              <AppBar
                position="fixed"
                sx={{
                  width: "95px",
                  boxShadow: 0,
                  top: "auto",
                  bottom: 180,
                  backgroundColor: "transparent",
                  marginBottom: "50px",
                }}
              >
                <Toolbar>
                  <Box sx={{ flexGrow: 1 }} />
                  <IconButton>
                    <Fab
                      color="primary"
                      aria-hidden="true"
                      sx={{ backgroundColor: "#765F00" }}
                    >
                      <RemoveCircleOutlineIcon />
                    </Fab>
                  </IconButton>
                </Toolbar>
              </AppBar>
            ),
          }}
        />
      )}
      {/* {enableKiosk === "1" ? <LogoCenter /> : null} */}
      {enableKiosk === "0" ? <DrawerRight /> : null}
      <Container
        id="target"
        style={{
          position: "relative",
          /*overflowY: "auto",*/
          marginTop: "1rem",
          backgroundColor: "transparent",
        }}
      >
        <Grid style={{ marginLeft: "0.7rem" }}>
          <Button
            style={{
              textTransform: "none",
              color: "gray",
              marginTop: "15px",
              backgroundColor: buttonColor,
              color: buttonText,
              borderColor: buttonColor,
              fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
            }}
            onClick={() =>
              enableRevenueCenter == 1
                ? history.push("/welcome")
                : history.push("/menu")
            }
          >
            Add More
          </Button>

          {cartItems && cartItems.length > 0 && (
            <Button
              style={{
                marginLeft: "1rem",
                textTransform: "none",
                fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                backgroundColor: buttonColor,
                color: buttonText,
                borderColor: buttonColor,
                marginTop: "15px",
              }}
              onClick={() =>
                Swal.fire({
                  title: "Store Message",
                  text: "This will remove all items from current order, do you wish to proceed?",
                  icon: "info",
                  fontSize: "1.5rem",
                  showCancelButton: true, // This will show the cancel button
                  confirmButtonText: "OK",
                  cancelButtonText: "Cancel",
                }).then((result) => {
                  if (result.isConfirmed) {
                    dispatch({
                      type: "SET_CART",
                      payload: [],
                    });
                    history.push(
                      parseInt(enableRevenueCenter) === 1 ? "/welcome" : "/menu"
                    );
                  }
                })
              }
            >
              Clear Cart
            </Button>
          )}
          <Typography
            className="content"
            style={{
              fontWeight: 700,
              paddingTop: "20px",
              fontSize: enableKiosk === "0" ? "1.25rem" : "1.75rem",
            }}
            gutterBottom
          >
            My Order
          </Typography>
        </Grid>
        <Box height="1rem" />
        <Grid container spacing={1}>
          {cartItems.length === 0 ? (
            <Typography
              style={{
                fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                textAlign: "center",
                width: "100%",
                color: "#bebebe",
              }}
              variant="h6"
            >
              NO ITEMS IN CHECK!
            </Typography>
          ) : (
            cartItems.map((item) => {
              const modifiers = item.modifiers?.sort(
                (a, b) => parseInt(a.ModifierIndex) - parseInt(b.ModifierIndex)
              );

              return (
                <React.Fragment key={item.ItemID}>
                  <TableContainer
                    id="checkoutTable"
                    component={Paper}
                    style={{ backgroundColor: "#EAEAEA" }}
                  >
                    <Table
                      className="tableOrderList"
                      style={{ tableLayout: "fixed" }}
                    >
                      <TableHead>
                        <TableRow className="checkoutMobileBorder">
                          <TableCell width="150px" className="checkcell01">
                            <img
                              // alt={item.FileName}
                              height="100%"
                              width="100%"
                              // title={item.FileName}
                              loading="lazy"
                              src={
                                item.FileName && item.FileName.length > 0
                                  ? siteImgSrc + item.FileName
                                  : null
                              }
                              style={{ borderRadius: "10px" }}
                            />
                          </TableCell>

                          <TableCell
                            id="checkoutItemName"
                            className="checkcell02"
                            colSpan={6}
                            style={{ width: "30%" }}
                          >
                            <Typography
                              component="h2"
                              variant="h5"
                              style={{
                                fontWeight: 700,
                                fontSize:
                                  enableKiosk === "0" ? "1.25rem" : "1.5rem",
                              }}
                              gutterBottom
                            >
                              {item.ItemName}
                            </Typography>
                            {item.modifiers && item.modifiers.length > 0 ? (
                              <Typography
                                style={{
                                  fontWeight: 700,
                                  marginTop: "1rem",
                                  fontSize:
                                    enableKiosk === "0" ? "1.25rem" : "1.5rem",
                                }}
                              >
                                Add-On:
                              </Typography>
                            ) : null}
                            {item.modifiers
                              ? modifiers.map((mod) => (
                                  <>
                                    <Typography
                                      component="span"
                                      style={{
                                        fontWeight: 500,
                                        fontSize:
                                          enableKiosk === "0"
                                            ? "1.25rem"
                                            : "1.5rem",
                                      }}
                                    >
                                      {mod.ItemDescription} - ${" "}
                                      {numberWithCommas(mod.DefaultPrice / 100)}
                                      <br></br>
                                    </Typography>
                                  </>
                                ))
                              : null}
                          </TableCell>

                          <TableCell align="center" style={{ width: "40%" }}>
                            <Grid
                              container
                              justifyContent="start"
                              className="tableRemoveAdd"
                            >
                              <Box
                                borderRadius="4px"
                                display="flex"
                                alignItems="center"
                                padding={
                                  enableKiosk === "0" ? ".25rem" : ".5rem"
                                }
                                style={{
                                  backgroundColor: buttonColor,
                                  color: buttonText,
                                  borderColor: buttonColor,
                                  border: "none",
                                }}
                              >
                                {item.comments != "Delivery Charge" &&
                                item.comments != "ItemTagMenu" ? (
                                  <>
                                    <Button
                                      style={{
                                        minWidth: "40px",
                                        backgroundColor: buttonColor,
                                        color: buttonText,
                                        borderColor: buttonColor,
                                        border: "none",
                                      }}
                                    >
                                      <RemoveIcon
                                        style={{
                                          backgroundColor: buttonColor,
                                          color: buttonText,
                                          borderColor: buttonColor,
                                          border: "none",
                                        }}
                                        fontSize={
                                          enableKiosk === "0"
                                            ? "1.25rem"
                                            : "1.5rem"
                                        }
                                        onClick={() => updateQuantity(item, -1)}
                                      />
                                    </Button>
                                    <Box
                                      style={{
                                        backgroundColor: buttonColor,
                                        color: buttonText,
                                        borderColor: buttonColor,
                                        border: "none",
                                      }}
                                      fontSize={
                                        enableKiosk === "0"
                                          ? "1.25rem"
                                          : "1.5rem"
                                      }
                                      margin={
                                        enableKiosk === "0" ? "0" : "0 1rem"
                                      }
                                    >
                                      {item.quantity}
                                    </Box>
                                    <Button
                                      style={{
                                        minWidth: "40px",
                                        backgroundColor: buttonColor,
                                        color: buttonText,
                                        borderColor: buttonColor,
                                        border: "none",
                                      }}
                                    >
                                      <AddIcon
                                        style={{
                                          backgroundColor: buttonColor,
                                          color: buttonText,
                                          borderColor: buttonColor,
                                          border: "none",
                                        }}
                                        fontSize={
                                          enableKiosk === "0"
                                            ? "1.25rem"
                                            : "1.5rem"
                                        }
                                        onClick={() => updateQuantity(item, 1)}
                                      />
                                    </Button>
                                  </>
                                ) : null}
                              </Box>
                            </Grid>
                          </TableCell>

                          <TableCell align="right" style={{ width: "30%" }}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                width: "100%",
                              }}
                            >
                              <Box
                                className="mb-2 flex items-center"
                                sx={{ justifyContent: "flex-end" }}
                              >
                                <Box className="flexRowCompact">
                                  <Box className="primaryItemDisplay">
                                    <Box
                                      className="flexTruncateBaseline"
                                      sx={{
                                        fontWeight: 700,
                                        fontSize:
                                          enableKiosk === "0"
                                            ? "1.25rem"
                                            : "1.5rem",
                                      }}
                                    >
                                      <span>$</span>
                                      <span>
                                        {numberWithCommas(
                                          (parseFloat(item.DefaultPrice) /
                                            (typeof item.DefaultPrice ===
                                            "number"
                                              ? 100
                                              : 1)) *
                                            item.quantity
                                        ) + (usePoints === 1 ? " pts" : "")}
                                      </span>
                                    </Box>
                                  </Box>

                                  {/* {parseFloat(item.DiscountTotal) > 0 && (
                                    <Box className="mb-15">
                                      <Box className="discountTag white">
                                        <span aria-label={`-${discountRate}%`}>
                                          -{discountRate}%
                                        </span>
                                      </Box>

                                      <Box className="originalPriceTag">
                                        <Box className="flexTruncateBaseline">
                                          <span>$</span>
                                          <span className="truncate">
                                            {numberWithCommas(
                                              parseFloat(item.DefaultPrice) *
                                                item.quantity
                                            )}
                                          </span>
                                        </Box>
                                      </Box>
                                    </Box>
                                  )} */}
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        <TableRow style={{ borderBottom: "1px solid #ebebeb" }}>
                          <TableCell></TableCell>

                          <TableCell className="checkcell02">
                            {item.modifiers
                              ? modifiers.map((mod) => (
                                  <>
                                    <Typography
                                      style={{
                                        fontWeight: 700,
                                        fontSize:
                                          enableKiosk === "0"
                                            ? "1.25rem"
                                            : "1.5rem",
                                      }}
                                    >
                                      {mod.ItemDescription}
                                    </Typography>
                                  </>
                                ))
                              : null}
                          </TableCell>
                          <TableCell
                            align="center"
                            className="checkcell03"
                          ></TableCell>
                          <TableCell align="center" className="checkcell04">
                            {item.modifiers
                              ? modifiers.map((mod) => (
                                  <>
                                    <Typography
                                      style={{
                                        fontWeight: 700,
                                        fontSize:
                                          enableKiosk === "0"
                                            ? "1.25rem"
                                            : "1.5rem",
                                      }}
                                    >
                                      ${" "}
                                      {numberWithCommas(mod.DefaultPrice / 100)}
                                    </Typography>
                                  </>
                                ))
                              : null}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </React.Fragment>
              );
            })
          )}
        </Grid>
        <Box height="1rem" />

        <TableContainer
          id="checkoutTotal"
          component={Paper}
          className="checkoutMobileFlexTotal"
        >
          {/* <Table className="checkoutMobileFlexTotalInner"> */}
          <Table>
            <TableBody>
              {/* SUBTOTAL */}
              <TableRow>
                <TableCell colSpan={3} style={{ width: "17%" }}>
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                    }}
                  >
                    Subtotal
                  </Typography>
                </TableCell>
                <TableCell colSpan={1} style={{ width: "auto" }}></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="right" style={{ width: "30%" }}>
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                    }}
                  >
                    $
                    {numberWithCommas(
                      // parseFloat(totalPriceItems - itemsTotalDiscount)
                      getSubtotalPrice()
                    )}
                  </Typography>
                </TableCell>
              </TableRow>

              {/* TIP */}
              {enableTip == 1 ? (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Typography
                      style={{
                        fontWeight: 700,
                        fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                      }}
                    >
                      Tip
                      <Button
                        style={{
                          textTransform: "none",
                          marginLeft: "10px",
                          color: "gray",
                          fontSize: "0.8rem",
                          backgroundColor: buttonColor,
                          borderColor: buttonColor,
                          border: "1px solid",
                          borderRadius: "6px",
                          padding: "5px",
                          alignItems: "center",
                          color: buttonText,
                        }}
                        onClick={() => setOpenTips(true)}
                      >
                        Change
                      </Button>
                    </Typography>
                  </TableCell>

                  <TableCell align="center"></TableCell>
                  <TableCell align="right" style={{ width: "30%" }}>
                    <Typography
                      style={{
                        fontWeight: 700,
                        fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                      }}
                    >
                      ${numberWithCommas(tips)}
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : null}

              {/* TAX */}
              <TableRow>
                <TableCell colSpan={3} style={{ width: "auto" }}>
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                    }}
                  >
                    Tax
                  </Typography>
                </TableCell>
                <TableCell colSpan={1} style={{ width: "auto" }}></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="right" style={{ width: "auto" }}>
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                    }}
                  >
                    $
                    {numberWithCommas(
                      // Math.round(
                      //   (totalPriceItems - itemsTotalDiscount) * tax * 100 +
                      //     1e-10
                      // ) / 100
                      getTotalTaxAmount()
                    )}
                  </Typography>
                </TableCell>
              </TableRow>

              {/* SERVICE FEE */}
              <TableRow></TableRow>

              {/* DISCOUNTS */}
              <TableRow>
                <TableCell colSpan={3} style={{ width: "auto" }}>
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                    }}
                  >
                    Discounts
                  </Typography>
                </TableCell>
                <TableCell colSpan={1} style={{ width: "auto" }}></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="right" style={{ width: "auto" }}>
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                    }}
                  >
                    ${numberWithCommas(getTotalDiscount())}
                  </Typography>
                </TableCell>
              </TableRow>

              {/* TOTAL AMOUNT */}
              <TableRow
                className="amountDueMobile"
                style={{ marginTop: "10px", paddingTop: "10px" }}
              >
                <TableCell colSpan={5} style={{ width: "auto" }}>
                  <Typography
                    component="h3"
                    variant="h4"
                    style={{
                      fontWeight: 700,
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                    }}
                  >
                    Amount Due
                  </Typography>
                </TableCell>
                <TableCell align="right" style={{ width: "auto" }}>
                  <Typography
                    component="h3"
                    variant="h4"
                    style={{
                      fontWeight: 700,
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                    }}
                  >
                    $
                    {numberWithCommas(
                      // parseFloat(totalPriceItems - itemsTotalDiscount) +
                      //   parseFloat(
                      //     Math.round(
                      //       (totalPriceItems - itemsTotalDiscount) * tax * 100 +
                      //         1e-10
                      //     ) / 100
                      //   )
                      getTotalPrice()
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <BlankSpace />
        <br />
        {/******* Start Message to Kitchen *******/}
        {parseInt(enableKiosk) === 1 ? (
          <Grid container>
            <Typography
              gutterBottom
              style={{
                fontWeight: 700,
                fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                marginTop: enableKiosk === "0" ? "1.125rem" : "2rem",
              }}
            >
              * Enter your name
            </Typography>
            <Grid item xs={12}>
              <div ref={enternameRef}>
                <TextField
                  className="eKioskInput"
                  style={{ width: "100%" }}
                  onChange={(e) => handleOnChangeName(e.target.value)}
                  onFocus={() => setKeyboardVisibleName(true)}
                  variant="outlined"
                  name="name"
                  value={name}
                  onClick={() => updateCaretPosition(enternameRef)} // Update caret position on click
                  placeholder=""
                  autoComplete="off"
                  InputLabelProps={{
                    style: { fontSize: "1.25rem" }, // Adjust based on enableKiosk
                  }}
                  InputProps={{
                    "aria-label": "name",
                    style: { fontSize: "1.25rem" }, // Adjust based on enableKiosk
                  }}
                />

                {keyboardVisibleName && (
                  <Keyboard
                    layoutName={layoutName}
                    onKeyPress={handleOnKeyPressName}
                    // onChange={handleOnChangeName}
                    inputName="name"
                  />
                )}
              </div>
            </Grid>
            <Typography
              gutterBottom
              style={{
                fontWeight: 700,
                fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                marginTop: enableKiosk === "0" ? "1.125rem" : "2rem",
              }}
            >
              Message to Kitchen
            </Typography>
            <Grid item xs={12}>
              <div ref={messageRef}>
                <TextField
                  className="eKioskInput"
                  style={{ width: "100%" }}
                  onChange={(e) => handleOnChange(e.target.value)}
                  onFocus={() => setKeyboardVisible(true)}
                  variant="outlined"
                  name="message"
                  value={messageToKitchen}
                  onClick={() => updateCaretPosition(messageRef)} // Update caret position on click
                  placeholder=""
                  autoComplete="off"
                  InputLabelProps={{
                    style: { fontSize: "1.25rem" }, // Adjust based on enableKiosk
                  }}
                  InputProps={{
                    "aria-label": "message",
                    style: { fontSize: "1.25rem" }, // Adjust based on enableKiosk
                  }}
                />

                {keyboardVisible && (
                  <Keyboard
                    layoutName={layoutName}
                    onKeyPress={handleOnKeyPress}
                    // onChange={handleOnChange}
                    inputName="message"
                  />
                )}
              </div>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Typography
              gutterBottom
              style={{
                fontWeight: 700,
                fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                marginTop: enableKiosk === "0" ? "1.125rem" : "2rem",
                paddingLeft: "1rem",
              }}
            >
              Message to Kitchen
            </Typography>
            <Grid item xs={12}>
              <Form provider={methods} onSubmit={handleSubmit}>
                <Input
                  className={enableKiosk === "1" ? "eKioskInput" : null}
                  onChange={setKitchenMsg}
                  variant="outlined"
                  name="message"
                  placeholder="Enter special instructions"
                  autoComplete="off"
                  border="1px solid #bebebe"
                />
              </Form>
            </Grid>
          </Grid>
        )}
        {/*******End Message to Kitchen *******/}
      </Container>
      <TipsDrawerSL open={openTips} setOpen={setOpenTips} />
      <SelectCreditCardDrawerSL
        open={openUserCards}
        setOpen={setOpenUserCards}
      />
      {parseInt(enableKiosk) === 0 && (
        <Suspense fallback={<div>Loading...</div>}>
          <ServiceOptionsDrawerSL
            open={openServiceOptions}
            menuName={props.location.state && props.location.state.menuName}
            pickupTimeLimits={pickupTimeLimits}
            availableOrderTypes={availableOrderTypes}
            siteSettings={siteSettings}
            setOpen={setOpenServiceOptions}
            updatePickupTimebyRevenueCenter={updatePickupTimebyRevenueCenter.bind(
              this
            )}
            setSelectedTime={setSelectedTime}
            revenueCenter={
              props.location.state && props.location.state.revenueCenter
            }
            selectedTime={selectedTime}
          />
        </Suspense>
      )}
      <Container
        id="target"
        style={{
          position: "relative",
          backgroundColor: "transparent",
        }}
      >
        <PaymentSelectorSL
          pickupTimebyRevenueCenter={pickupTimebyRevenueCenter}
          selectedTime={selectedTime}
          setOpenUserCards={setOpenUserCards}
          getSubtotalPrice={getSubtotalPrice}
          getTotalTaxAmount={getTotalTaxAmount}
          getTotalDiscount={getTotalDiscount}
          getTotalPrice={getTotalPrice}
        />
        <BlankSpace />
      </Container>
      {enableKiosk === "1" ? (
        <BottomCheckOutNav
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        />
      ) : null}
      {/* <ChangeTextSize /> */}
      {enableKiosk === "0" ? <ScrollToDown /> : null}
      {enableKiosk === "0" ? <ScrollToUp /> : null}
      {enableNewUI === "1" ? (
        <>
          <BarTop />
          <BarBottom />
        </>
      ) : null}
    </React.Fragment>
  );
};
