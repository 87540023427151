import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import config from "../../../config";
import "../../../App.css";
import AOS from "aos";
import "aos/dist/aos.css";

const {
  REACT_APP_RESTAURANT_NAME: restaurantName = "",
  REACT_APP_RESTAURANT_ADDRESS: restaurantAddress = "",
  REACT_APP_BUTTON_COLOR: buttonColor = "",
  REACT_APP_BUTTON_TEXT: buttonText = "",
  REACT_APP_MEDIA_SRC: mediaSrc = "",
  REACT_APP_SERVICE_OPTIONS: serviceOptions = "",
  REACT_APP_ENABLE_ELOYALTY: enableEloyalty = "",
  REACT_APP_TEXT_HIGHLIGHT: textHighlight = "",
} = process.env;

export const ContactSlider = () => {
  const [contactContent, setContactContent] = useState({ title: "" });

  useEffect(() => {
    AOS.init({ duration: 600 });

    // Lazy load the image
    const img = new Image();
    img.src = `${mediaSrc}contact_us_bg.webp`;
    img.onload = () => {
      document.querySelector(
        ".ContactHeader"
      ).style.backgroundImage = `url(${img.src})`;
    };

    // Load contact content
    const contactContentText = config.contactcontent || { title: "" };
    setContactContent(contactContentText);
  }, []);

  return (
    <div
      className="ContactHeader page-info"
      data-aos-easing="ease-in-out"
      data-aos-delay="50"
      // data-aos="fade-top"
      style={{
        color: buttonText,
        backgroundImage: `url(${mediaSrc}contact_us_bg.webp)`,
      }}
    >
      <Container
        className="page-info-right"
        data-aos-easing="ease-in-out"
        data-aos-delay="100"
      >
        <Row data-aos-easing="ease-in-out" data-aos-delay="200">
          <Col
            lg={9}
            className="aos-init aos-animate"
            data-aos="fade-left"
            data-aos-easing="ease-in-out"
            data-aos-delay="300"
          >
            <p className="white" style={{ fontSize: "large" }}>
              Service Options: {serviceOptions}
            </p>
            <h1 className="left blankspace" style={{ color: textHighlight }}>
              {contactContent.title}
            </h1>
            <p className="white blankspace" style={{ fontSize: "large" }}>
              Spread the word and help your neighbors get to know{" "}
              {restaurantName}, today!
            </p>
            <p className="white" style={{ fontSize: "large" }}>
              {restaurantAddress}
            </p>
            {enableEloyalty === "1" && (
              <Button
                className="btn-red whitespace margin-bottom-10"
                data-aos-easing="ease-in-out"
                data-aos-delay="400"
                href="/register"
                style={{
                  backgroundColor: buttonColor,
                  color: buttonText,
                  borderColor: buttonColor,
                }}
              >
                Join our eLoyalty Program
              </Button>
            )}
          </Col>
          {enableEloyalty === "1" && (
            <Col
              lg={3}
              className="aos-init aos-animate"
              data-aos="fade-right"
              data-aos-easing="ease-in-out"
              data-aos-delay="500"
            >
              <img
                className="PhonerightImg"
                data-aos-easing="ease-in-out"
                data-aos-delay="600"
                title={`${restaurantName} / ${contactContent.title}`}
                src={`${mediaSrc}PhoneImg.webp`}
                width="200"
                height="600"
                alt={`${restaurantName} Mobile View`}
                loading="lazy"
              />
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};
