import React, { useEffect, useState, useMemo, memo, useRef } from "react";
import { Typography, Grid, Container, Box } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { SingleDatePicker } from "react-dates";
import "rc-time-picker/assets/index.css";
import {
  setSelectedServiceOption,
  setOrderProcessingDate,
  setOrderTimeSelectValue,
} from "../../../redux/publicReducer/actions";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import BlankSpace from "features/eComm/components/BlankSpace";
import "react-simple-keyboard/build/css/index.css";
import { set } from "date-fns";
import { isEqual } from "lodash";

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;

const tableNumber = sessionStorage.getItem("table");
const enablePickup =
  tableNumber && tableNumber !== "none"
    ? 0
    : parseInt(process.env.REACT_APP_PICKUP);
const enableDineIN =
  tableNumber && tableNumber !== "none"
    ? 1
    : parseInt(process.env.REACT_APP_DINE_IN);
const enalbeDelivery =
  tableNumber && tableNumber !== "none"
    ? 0
    : parseInt(process.env.REACT_APP_DELIVERY);
const enableRevenueCenter = process.env.REACT_APP_ENABLE_REVENUE_CENTER;
const enableKiosk = process.env.REACT_APP_ENABLE_EKIOSK;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;
const buttonText = process.env.REACT_APP_BUTTON_TEXT;

//--Time Conditions--//
const startHour = 7;
const startTime = 420;
const endHour = 20;
const endTime = 1220;
const endMinutes = 0;

const ServiceOptionsDrawerSL = ({
  open,
  menuName,
  pickupTimeLimits,
  availableOrderTypes,
  siteSettings,
  setOpen,
  updatePickupTimebyRevenueCenter,
  setSelectedTime,
  revenueCenter,
  selectedTime,
}) => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(moment());
  const [focused, setFocused] = useState(false);
  const [timeConstant, setTimeConstant] = useState([]);
  const [pickupCounter, setPickupCounter] = useState([]);
  const [pickupTimebyRevenueCenter, setPickupTimebyRevenueCenter] = useState(
    []
  );

  //const [time, setTime] = useState(moment().add("20", "mins"));
  const { orderProcessingDate, orderProcessingType, orderTimeSelectValue } =
    useSelector((state) => state.public);
  const history = useHistory();

  const { cartItems } = useSelector((state) => state.cart);

  // group cart items by revenueCenter
  const revenueCenters = cartItems.reduce((rv, item) => {
    const center = item.RevenueCenter || item.revenueCenter;

    // Initialize the array if it doesn't exist, then push the item
    if (!rv[center]) {
      rv[center] = [];
    }

    rv[center].push(item);
    return rv;
  }, {});

  const menuNames = cartItems.reduce(function (rv, x) {
    (rv[x["menuName"]] = rv[x["menuName"]] || []).push(x);
    return rv;
  }, {});
  const uniqueMenuNames = [...new Set(Object.keys(menuNames))];

  const updateTime = (e) => {
    console.log(e, "e");
    // dispatch(setOrderProcessingDate(moment()))
    dispatch(setOrderTimeSelectValue(e?.value ?? ""));
    let date;
    if (Date.parse(orderProcessingDate) > 0) {
      const timeAndDate = moment(
        moment(orderProcessingDate).format("MMMM DD, YYYY") + " " + e.value
      );
      //console.log(timeAndDate, "TIME AND DATE 1234");
      date = timeAndDate;
      dispatch(setOrderProcessingDate(timeAndDate));
    } else {
      //alert("Invalid Date");
      const today = new Date();
      const defautTimeAndDate = moment(
        moment(orderProcessingDate).format("MMMM DD, YYYY") + " " + today
      );
      date = defautTimeAndDate;
    }

    // If these fields doesn't have value update the order processing date
    if (!orderProcessingDate && !orderProcessingType && !orderTimeSelectValue) {
      dispatch(setOrderProcessingDate(date));
    }
    //For revenue center enabled
    if (enableRevenueCenter == 1) {
      const revPickup = { revenueCenter: e.rev, pickup: e.value };

      if (pickupTimebyRevenueCenter && pickupTimebyRevenueCenter.length > 0) {
        setPickupTimebyRevenueCenter(
          pickupTimebyRevenueCenter.filter((i) => i.revenueCenter != e.rev)
        );
        return;
      }

      setPickupTimebyRevenueCenter((pickupTimebyRevenueCenter) => [
        ...pickupTimebyRevenueCenter,
        revPickup,
      ]);
    }
  };

  const addDeliveryCharge = async () => {
    try {
      const url = `${apiBaseURL}/items/item-charge-delivery/${serverId}/${siteId}`;
      const response = await axios.get(url);

      if (response.data && response.data.length > 0) {
        const itemChargeDelivery = response.data[0];

        if (cartItems.length > 0) {
          const cleanItemChargeDelivery = cartItems.filter(
            (item) => item.comments !== "Delivery Charge"
          );

          const deliveryChargeItem = {
            ...itemChargeDelivery,
            quantity: 1,
            modifiers: [],
            comments: "Delivery Charge",
          };

          dispatch({
            type: "SET_CART",
            payload: [...cleanItemChargeDelivery, deliveryChargeItem],
          });
        }
      }
    } catch (error) {
      console.error("Error adding delivery charge:", error);
    }
  };

  const removeDeliveryCharge = () => {
    const cartItemsRemoveCharge = cartItems.filter(
      (i) => i.comments !== "Delivery Charge"
    );

    dispatch({
      type: "SET_CART",
      payload: cartItemsRemoveCharge,
    });
  };

  useEffect(() => {
    if (orderProcessingType != 2) {
      removeDeliveryCharge();
    }
  }, []);

  useEffect(() => {
    updatePickupTimebyRevenueCenter(pickupTimebyRevenueCenter);
  }, [pickupTimebyRevenueCenter]);

  //get pickup limit for today
  useEffect(() => {
    const baseUrl =
      enableRevenueCenter == 1
        ? `${apiBaseURL}/items/pickup-orders-today-revenue-center/`
        : `${apiBaseURL}/items/pickup-orders-today/`;

    const url = baseUrl + serverId + "/" + siteId;
    axios
      .get(url)
      .then((response) => {
        setPickupCounter(response.data);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    var tempTimeConstant = [];
    var d = new Date();
    var selectedDate =
      Date.parse(orderProcessingDate) > 0 ? new Date(orderProcessingDate) : d;
    var hour = d.getHours(); //get current hour
    var min = d.getMinutes(); //get current min
    var cdate = d.getDate();
    var cyear = d.getFullYear();
    var cmonth = d.getMonth();

    var interval = 15; //minutes interval
    var times = []; // time array
    var tt = startTime; // start time
    var ap = ["AM", "PM"]; // AM-PM

    //--round time--//
    var date_r = new Date(d.getTime());

    // date_r.setMinutes(((((date_r.getMinutes() + 7.5) / 15) | 0) * 15) % 60);
    date_r.setMinutes(date_r.getMinutes() + interval);
    //date_r.setHours(date_r.setMinutes(date_r.getMinutes() + 8));
    //--end round time--//
    if (selectedDate) {
      //loop to increment the time and push results in array
      for (var i = 0; tt <= 24 * 60; i++) {
        var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format

        const hh_am_pm = hh > 11 ? hh : ((hh + startHour) % 12) + 1;
        var mm = tt % 60; // getting minutes of the hour in 0-55 format
        let final_hh = ("0" + (hh % 12)).slice(-2);
        final_hh = final_hh == 0 ? 12 : final_hh;
        times[i] =
          final_hh +
          ":" +
          ("0" + mm).slice(-2) +
          " " +
          ap[Math.floor(hh_am_pm / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]

        tt = tt + interval;

        if (tt <= endTime) {
          //console.log(hh + "=" + hour);
          //console.log(mm + "=" + (min + 15));
          if (
            selectedDate.getDate() == cdate &&
            selectedDate.getMonth() == cmonth &&
            selectedDate.getFullYear() == cyear
          ) {
            //--set time to current option--//
            let timeOption = new Date(d.getTime());
            timeOption.setHours(hh);
            timeOption.setMinutes(mm);

            if (
              //(hh >= hour && (mm >= min + 15 || mm <= min - 30)) ||
              (hh >= hour && timeOption.getTime() > date_r.getTime()) ||
              hour < startHour
            ) {
              tempTimeConstant.push({ code: times[i], name: times[i] });
              // console.log("push:" + times[i]);
            }
          } else {
            tempTimeConstant.push({ code: times[i], name: times[i] });
          }
        }
      }
      setTimeConstant(tempTimeConstant);

      //---Start Override Pickup Time Limits---//
      let sortedTempTimeConstant = [];
      if (
        enableRevenueCenter == 1 &&
        pickupTimeLimits &&
        menuName &&
        revenueCenters
      ) {
        const tempTimeConstant = [];
        const serviceOption =
          orderProcessingType == 0
            ? "Dine In"
            : orderProcessingType == 1
              ? "Pick Up"
              : "Delivery";

        // improved forEach loop to add pickup time
        pickupTimeLimits.forEach(
          ({ MenuName, OrderType, PickUpTime, RevenueCenter }) => {
            if (
              MenuName?.includes(menuName) &&
              OrderType === serviceOption &&
              Object.keys(revenueCenters).some((key) =>
                key.includes(RevenueCenter)
              )
            ) {
              tempTimeConstant.push({
                code: PickUpTime,
                name: PickUpTime,
              });
            }
          }
        );

        sortedTempTimeConstant = tempTimeConstant.sort((a, b) => {
          return (
            new Date("1970/01/01 " + a.code) - new Date("1970/01/01 " + b.code)
          );
        });

        setTimeConstant(sortedTempTimeConstant);
        // console.log(tempTimeConstant, "tempTimeConstant");
      } else {
        if (pickupTimeLimits) {
          const tempTimeConstant = [];
          const serviceOption =
            orderProcessingType == 0
              ? "Dine In"
              : orderProcessingType == 1
                ? "Pick Up"
                : "Delivery";

          pickupTimeLimits.forEach((p) => {
            if (p.OrderType === serviceOption) {
              tempTimeConstant.push({
                code: p.PickUpTime,
                name: p.PickUpTime,
              });
            }
          });

          sortedTempTimeConstant = tempTimeConstant.sort((a, b) => {
            return (
              new Date("1970/01/01 " + a.code) -
              new Date("1970/01/01 " + b.code)
            );
          });

          setTimeConstant(sortedTempTimeConstant);
        }
      }

      //---End Override Pickup Time Limits---//

      if (
        enableKiosk === 0 &&
        selectedDate.getDate() == cdate &&
        selectedDate.getMonth() == cmonth &&
        selectedDate.getFullYear() == cyear &&
        (hour > endHour || (hour == endHour && min >= endMinutes))
      ) {
        alert(
          "Store is closed. You can order on the next day when store is open."
        );
        if (enableRevenueCenter == 1) {
          history.push("/welcome");
        } else {
          history.push("/menu");
        }
      }
      if (orderProcessingDate) {
        if (orderProcessingDate && orderTimeSelectValue) return; // If these state has value don't update it
        if (
          selectedDate.getDate() > cdate ||
          (selectedDate.getMonth() == cmonth &&
            selectedDate.getFullYear() == cyear) ||
          selectedDate.getMonth() > cmonth ||
          selectedDate.getFullYear() > cyear
        ) {
          if (orderProcessingType == 0 && siteSettings.FutureDateOnOff == 0) {
            // alert("Dine In is for current date only.");
            dispatch(setOrderProcessingDate(moment(d, "MM-DD-YYYY")));
          }
          if (orderProcessingType == 1 && siteSettings.FutureDateOnOff == 0) {
            // alert("Pickup is for current date only.");
            dispatch(setOrderProcessingDate(moment(d, "MM-DD-YYYY")));
          }
          if (orderProcessingType == 2 && siteSettings.FutureDateOnOff == 0) {
            // alert("Delivery is for current date only.");
            dispatch(setOrderProcessingDate(moment(d, "MM-DD-YYYY")));
          }
        }
      }
    }
  }, [date, siteSettings, pickupTimeLimits, menuName, orderProcessingType]);

  const prevTimeConstantRef = useRef(timeConstant);
  useEffect(() => {
    // Run effect only if timeConstant has changed
    if (
      !isEqual(prevTimeConstantRef.current, timeConstant) &&
      !orderTimeSelectValue
    ) {
      if (!orderProcessingDate && timeConstant && timeConstant.length < 1) {
        dispatch(setOrderProcessingDate(""));
      }

      if (timeConstant && timeConstant.length > 0) {
        dispatch(setOrderTimeSelectValue(timeConstant[0]?.code));
      }

      // Update the ref after the effect
      prevTimeConstantRef.current = timeConstant;
    }
  }, [orderProcessingDate, timeConstant, dispatch, revenueCenter]);

  useEffect(() => {
    uniqueMenuNames.map((menu) => {
      const url =
        apiBaseURL +
        "/items/item-tag-menu/" +
        serverId +
        "/" +
        siteId +
        "/" +
        menu;
      axios
        .get(url)
        .then((response) => {
          const itemTagMenu = response.data[0];
          if (cartItems.length !== 0 && itemTagMenu) {
            const cleanItemTagMenuCartItems = cartItems.filter(
              (i) => i.comments !== "ItemTagMenu"
            );

            dispatch({
              type: "SET_CART",
              payload: cleanItemTagMenuCartItems,
            });

            dispatch({
              type: "SET_CART",
              payload: [
                ...cleanItemTagMenuCartItems,
                {
                  ...itemTagMenu,
                  quantity: 1,
                  modifiers: [],
                  comments: "ItemTagMenu",
                },
              ],
            });
          }
        })
        .catch((error) => {});
    });
  }, []);

  const { displayMessage } = useMemo(() => {
    const formattedDateTime = moment(
      moment(orderProcessingDate).format("MMMM DD, YYYY") +
        " " +
        orderTimeSelectValue
    ).format("MMMM DD, YYYY hh:mm a");

    const formattedDate = moment(orderProcessingDate).format("MMMM DD, YYYY");

    const isInvalidDate = formattedDateTime === "Invalid date";
    const hasTableNumber = tableNumber && tableNumber !== "none";

    const displayMessage = isInvalidDate
      ? `No time slots for ${menuName} available. Please remove the items from your cart and choose a different menu.`
      : hasTableNumber
        ? formattedDate
        : formattedDateTime;

    return {
      displayMessage,
    };
  }, [orderProcessingDate, orderTimeSelectValue, menuName]);

  const orderType = useMemo(() => {
    switch (orderProcessingType) {
      case 0:
        return "Dine In";
      case 1:
        return "Pickup";
      case 2:
        return "Delivery";
      default:
        return "Unknown";
    }
  }, [orderProcessingType]);
  console.log(orderTimeSelectValue, selectedTime, "orderTimeSelectValue");
  return (
    <Container id="target">
      <Grid open={open} onClose={() => setOpen(false)} anchor="bottom">
        <Box paddingTop="2rem" paddingBottom="2rem">
          <Typography
            className="content"
            style={{
              fontWeight: 700,
              fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
            }}
            gutterBottom
          >
            Select Service Option
          </Typography>
          <Typography gutterBottom>
            {`${orderType} - `}
            {displayMessage}{" "}
          </Typography>
          <Box height="1rem" />
          <Grid container spacing={2}>
            {enableDineIN == 1 &&
            availableOrderTypes &&
            availableOrderTypes.includes("Dine In") ? (
              <Grid item xs={4}>
                <Box
                  height="75px"
                  // width="100%"
                  display="flex"
                  alignItems="center"
                  borderRadius="10px"
                  justifyContent="center"
                  textAlign="center"
                  border={orderProcessingType != 0 && "1px solid #bebebe"}
                  color={orderProcessingType == 0 && buttonText}
                  bgcolor={orderProcessingType == 0 && buttonColor}
                  onClick={() => {
                    dispatch(setSelectedServiceOption(0));
                    removeDeliveryCharge();
                  }}
                >
                  <Typography className="content">Dine In</Typography>
                </Box>
              </Grid>
            ) : null}
            {enablePickup == 1 &&
            availableOrderTypes &&
            availableOrderTypes.includes("Pick Up") ? (
              <Grid item xs={4}>
                <Box
                  height="75px"
                  // width="100%"
                  display="flex"
                  alignItems="center"
                  borderRadius="10px"
                  justifyContent="center"
                  textAlign="center"
                  border={orderProcessingType != 1 && "1px solid #bebebe"}
                  color={orderProcessingType == 1 && buttonText}
                  bgcolor={orderProcessingType == 1 && buttonColor}
                  onClick={() => {
                    dispatch(setSelectedServiceOption(1));
                    // const d = new Date();
                    // dispatch(setOrderProcessingDate(moment(d, "MM-DD-YYYY")));
                    // setDate(moment(d, "MM-DD-YYYY"));
                    removeDeliveryCharge();
                  }}
                >
                  <Typography className="content">Pickup</Typography>
                </Box>
              </Grid>
            ) : null}
            {enalbeDelivery == 1 &&
            availableOrderTypes &&
            availableOrderTypes.includes("Delivery") ? (
              <Grid item xs={4}>
                <Box
                  height="75px"
                  // width="100%"
                  display="flex"
                  borderRadius="10px"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  border={orderProcessingType != 2 && "1px solid #bebebe"}
                  color={orderProcessingType == 2 && buttonText}
                  bgcolor={orderProcessingType == 2 && buttonColor}
                  onClick={() => {
                    dispatch(setSelectedServiceOption(2));
                    // const d = new Date();
                    // dispatch(setOrderProcessingDate(moment(d, "MM-DD-YYYY")));
                    // setDate(moment(d, "MM-DD-YYYY"));
                    addDeliveryCharge();
                  }}
                >
                  <Typography className="content">Room Delivery</Typography>
                </Box>
              </Grid>
            ) : null}
          </Grid>
          <Box height="1rem" />
          <Grid>
            {timeConstant && timeConstant.length > 0 ? (
              <Box>
                <Typography
                  className="content"
                  style={{
                    fontWeight: 600,
                    fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                  }}
                >
                  Select date
                </Typography>
                <SingleDatePicker
                  className="content"
                  focused={focused}
                  numberOfMonths={1}
                  date={orderProcessingDate}
                  id="date_input"
                  onDateChange={(date) => {
                    if (
                      date === null ||
                      !date.isSame(orderProcessingDate, "day")
                    ) {
                      console.log("Selected date is not the current date.");
                      dispatch(setOrderProcessingDate(orderProcessingDate));
                      setFocused(false);
                      setDate(orderProcessingDate);
                    } else {
                      console.log("Selected date is the current date.");
                      dispatch(setOrderProcessingDate(date));
                      setFocused(false);
                      setDate(date);
                    }
                  }}
                  placeholder="Select date"
                  orientation="horizontal"
                  onFocusChange={(data) => {
                    setFocused(data.focused);
                  }}
                  isDayBlocked={(day) => {
                    const d = new Date(day);
                    return d.getDay() === 0;
                  }}
                />
              </Box>
            ) : null}
          </Grid>
          <Box height="1rem" />

          <Grid container hidden={tableNumber && tableNumber !== "none"}>
            {timeConstant && timeConstant.length > 0 ? (
              <Box width="100%">
                <Typography className="content" style={{ fontWeight: 600 }}>
                  Select time
                </Typography>
                {enableRevenueCenter == 1 &&
                revenueCenters &&
                orderProcessingType >= 0 &&
                orderProcessingType <= 2 ? (
                  <>
                    {Object.keys(revenueCenters).find((item) =>
                      item.includes(revenueCenter)
                    ) ? (
                      <div key={revenueCenter}>
                        <Typography className="content">
                          {orderProcessingType == 0
                            ? "Dine In at " + revenueCenter
                            : orderProcessingType == 1
                              ? "Pickup at " + revenueCenter
                              : "Delivery from " + revenueCenter}
                        </Typography>
                        <Select
                          isSearchable={false}
                          key={JSON.stringify(timeConstant)}
                          label={revenueCenter}
                          className="content"
                          options={
                            timeConstant &&
                            timeConstant.map((time) => {
                              const revCounter = pickupCounter.filter(
                                (p) => Object.keys(p) == revenueCenter
                              )[0];

                              const orderCount =
                                revCounter &&
                                revCounter[revenueCenter][time.code];

                              if (pickupTimeLimits) {
                                const isFoundRevenueCenter =
                                  pickupTimeLimits.some(
                                    (p) => p.RevenueCenter == revenueCenter
                                  );

                                let orderLimit;
                                if (menuName == "none" || menuName == "") {
                                  orderLimit = pickupTimeLimits.filter(
                                    (p) =>
                                      p.RevenueCenter == revenueCenter &&
                                      String(p.PickUpTime)
                                        .toUpperCase()
                                        .localeCompare(
                                          String(time.code).toUpperCase()
                                        ) == 0
                                  )[0];
                                } else {
                                  orderLimit = pickupTimeLimits.filter(
                                    (pickupTime) => {
                                      const {
                                        MenuName,
                                        RevenueCenter,
                                        PickUpTime,
                                      } = pickupTime;

                                      return (
                                        MenuName &&
                                        MenuName.includes(menuName) &&
                                        RevenueCenter === revenueCenter &&
                                        String(PickUpTime).toUpperCase() ===
                                          String(time.code).toUpperCase()
                                      );
                                    }
                                  )[0];
                                }

                                if (
                                  orderLimit &&
                                  String(orderLimit.PickUpTime)
                                    .toUpperCase()
                                    .localeCompare(
                                      String(time.name).toUpperCase()
                                    ) == 0
                                ) {
                                  if (
                                    orderCount &&
                                    orderCount >= orderLimit.AvailableOrders
                                  ) {
                                    return {
                                      label:
                                        time.name +
                                        " Full - No Available Order Slots",
                                      value: "",
                                      isDisabled: true,
                                      rev: "",
                                    };
                                  } else {
                                    return {
                                      label: time.name,
                                      value: time.code,
                                      rev: revenueCenter,
                                    };
                                  }
                                } else {
                                  if (pickupTimeLimits.length < 1) {
                                    return {
                                      label: time.name,
                                      value: time.code,
                                      rev: revenueCenter,
                                    };
                                  } else {
                                    if (isFoundRevenueCenter) {
                                      return {
                                        label: time.name,
                                        value: time.code,
                                        rev: "hidden",
                                        isDisabled: true,
                                      };
                                    } else {
                                      return {
                                        label: time.name,
                                        value: time.code,
                                        rev: revenueCenter,
                                      };
                                    }
                                  }
                                }
                              } else {
                                return {
                                  label: time.name,
                                  value: time.code,
                                  rev: revenueCenter,
                                };
                              }
                            })
                          }
                          filterOption={(option) => {
                            if (option.data.rev === "hidden") {
                              return false;
                            } else {
                              return option;
                            }
                          }}
                          value={
                            orderTimeSelectValue
                              ? {
                                  label: orderTimeSelectValue,
                                  value: orderTimeSelectValue,
                                  rev: revenueCenter,
                                }
                              : selectedTime
                            //timeConstant.map((time, i) => {
                            //   if (i === 0) {
                            //     const isFullLimit = pickupCounter.filter(
                            //       (p) =>
                            //         Object.keys(p) == item &&
                            //         Object.keys(p).find(
                            //           (i) =>
                            //             String(Object.keys(p[i])[0])
                            //               .toUpperCase()
                            //               .localeCompare(
                            //                 String(time.code).toUpperCase()
                            //               ) === 0 &&
                            //             pickupTimeLimits.find(
                            //               (ptl) =>
                            //                 ptl.RevenueCenter == item &&
                            //                 String(ptl.PickUpTime)
                            //                   .toUpperCase()
                            //                   .localeCompare(
                            //                     String(time.code).toUpperCase()
                            //                   ) === 0
                            //             ) &&
                            //             pickupTimeLimits.find(
                            //               (ptl2) =>
                            //                 ptl2.RevenueCenter == item &&
                            //                 parseInt(p[i][time.code]) ===
                            //                   parseInt(ptl2.AvailableOrders)
                            //             )
                            //         )
                            //     );
                            //     let notAvailableTime;
                            //     if (isFullLimit[0]) {
                            //       notAvailableTime = Object.keys(
                            //         isFullLimit[0][item]
                            //       )[0];
                            //     }

                            //     if (
                            //       isFullLimit[0] &&
                            //       String(notAvailableTime)
                            //         .toUpperCase()
                            //         .localeCompare(
                            //           String(time.code).toUpperCase()
                            //         ) === 0
                            //     ) {
                            //       return {
                            //         label:
                            //           time.name +
                            //           " Full - No Available Order Slots",
                            //         value: "",
                            //         isDisabled: true,
                            //         rev: "",
                            //       };
                            //     } else {
                            //       return {
                            //         label: time.name,
                            //         value: time.code,
                            //         rev: item,
                            //       };
                            //     }
                            //   }
                            // })
                          }
                          styles={{
                            menu: (provided, state) => ({
                              ...provided,
                              fontSize: "large",
                            }),

                            singleValue: (provided, state) => ({
                              ...provided,
                              fontSize: "large",
                            }),
                          }}
                          onChange={(e) => {
                            console.log(e, "timers");
                            setSelectedTime(e);
                            updateTime(e);
                          }}
                        />
                      </div>
                    ) : null}
                  </>
                ) : (
                  <Select
                    isSearchable={false}
                    className="content"
                    key={JSON.stringify(timeConstant)}
                    options={
                      timeConstant &&
                      timeConstant.map((time) => {
                        return {
                          label: time.name,
                          value: time.code,
                        };
                      })
                    }
                    value={
                      orderTimeSelectValue
                        ? {
                            label: orderTimeSelectValue,
                            value: orderTimeSelectValue,
                            rev: revenueCenter,
                          }
                        : selectedTime
                    }
                    styles={{
                      menu: (provided, state) => ({
                        ...provided,
                        fontSize: "large",
                      }),

                      singleValue: (provided, state) => ({
                        ...provided,
                        fontSize: "large",
                      }),
                    }}
                    onChange={updateTime}
                  />
                )}
              </Box>
            ) : null}
          </Grid>
          <BlankSpace />
          <div></div>
        </Box>
      </Grid>
    </Container>
  );
};

export default memo(ServiceOptionsDrawerSL);
