import React, { useCallback, useEffect, useState } from "react";
import SpeechRecognition from "react-speech-recognition";
import Rose from "../assets/rose.jpeg";
import Ruby_processing from "../assets/ruby-processing.gif";
import Ruby_listening from "../assets/ruby-listening.gif";
import Rose_idle from "../assets/rose_idle.mp4";
import Ruby_analying from "../assets/ruby-analyzing.gif";
import Beep from "../assets/beep.mp3";
import { Box, IconButton, Paper, Typography } from "@mui/material";
import useAzureSpeech from "hooks/useAzureSpeech";
import { useCookies } from "react-cookie";
import axios from "axios";
import {
  datectCancel,
  describeItem,
  detectAffirmationOrDenial,
  detectDelivery,
  detectOrder,
  detectPickup,
  findClosestItem,
} from "utils/helpers";
import {
  fetchBIAPI,
  processItemsWithModifiers,
  updateConversationWithProduct,
  updateConversations,
} from "utils/services";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  setOrderProcessingDate,
  setOrderTimeSelectValue,
  setSelectedServiceOption,
} from "redux/publicReducer/actions";
import moment from "moment";
import { Chip } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { SET_PRODUCT_WITH_MODIFIERS } from "redux/rubyOrderReducer/constant";
import MultipleProductWithModifiersDialog from "./MultipleProductWithModifiersDialog";
import { set } from "date-fns";
const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const siteName = process.env.REACT_APP_RESTAURANT_NAME;
const rubyAIUri = process.env.REACT_APP_RUBY_AI_URI;
const rubyConvoUri = process.env.REACT_APP_RUBY_CONVO_URI;
const aiBiAPI = process.env.REACT_APP_AIBI_URI;
const enableSL = parseInt(process.env.REACT_APP_ENABLE_SL);
const greetings = () => {
  const currentTime = new Date();
  const hours = currentTime.getHours();

  if (hours >= 4 && hours < 12) {
    return "good morning";
  } else if (hours >= 12 && hours < 18) {
    return "good afternoon";
  } else {
    return "good evening";
  }
};

function updateStateWithValidValues(serviceOptionDetails, prevData) {
  // Initialize an empty object to store valid key-value pairs
  let validData = {};

  // Iterate over the keys in the serviceOptionDetails object
  Object.keys(serviceOptionDetails).forEach((key) => {
    const value = serviceOptionDetails[key];

    // Check if the value is not "invalid"
    if (value !== "invalid") {
      validData[key] = value;
    }
  });

  // Assume setState is a function to update your state
  return {
    ...prevData,
    ...validData,
  };
}
function NewAISpeech({ availableProducts = [], revenueCenter, menuName }) {
  const history = useHistory();
  const [{ customerDetails }, setCookieSICName, removeCookieSICName] =
    useCookies(["customerDetails"]);
  const senderId = localStorage.getItem("sender_id") || "default";
  const [checkoutDetails, setCheckoutDetails] = useState({
    type: "",
    time: "",
    date: "",
  });
  const dispatch = useDispatch();
  const {
    cart: { cartItems },
    rubyOrder: { orderStatus },
  } = useSelector((state) => state);

  const [availableTimes, setAvailableTimes] = useState([]);
  const [openModifierDialog, setOpenModifierDialog] = useState(false);
  const [goToCheck, setGoToCheck] = useState(false);

  const firstName = customerDetails?.FirstName || "";
  const rubyInitMessage = `Hello ${greetings()}${
    firstName ? ` ${firstName},` : ","
  } how may I help you today?`;

  const [
    text,
    setText,
    response,
    setResponse,
    status,
    setStatus,
    startContinuousRecognition,
    stopContinuousRecognition,
    isSpeaking,
    setIsSpeaking,
    readText,
  ] = useAzureSpeech(rubyInitMessage);

  const handleCloseMultipleProductWithModifiersDialog = async (
    itemName,
    quantity,
    itemsWithModifiersLeft = []
  ) => {
    const remainingItems = itemsWithModifiersLeft.length;
    let message =
      remainingItems === 0
        ? `${quantity} ${itemName} added to your cart, is there anything else?`
        : `${quantity} ${itemName} added to your cart, there are ${remainingItems} items that you need to modify`;
    await updateConversations(text, message, senderId);
    toast.success(message, {
      autoClose: 7500,
    });
    setResponse(message);
    dispatch({
      type: SET_PRODUCT_WITH_MODIFIERS,
      payload: itemsWithModifiersLeft,
    });
    if (remainingItems === 0) {
      dispatch({
        type: "INIT_ORDER",
        payload: {
          productDetails: {},
          message: message,
        },
      });
      setOpenModifierDialog(false);
    }
  };

  const orderProcess = async (productItems, message) => {
    const products = productItems
      ?.map((product) => {
        if (product) {
          delete product?.mods;
          product.modifiers = [];
          product.quantity = parseInt(product.quantity);
          product.expiration = Date.now() + 86400000;
        }

        return product;
      })
      .filter((item) => item?.ItemID);
    await updateConversations(text, message, senderId);
    if (products.length > 0) {
      dispatch({
        type: "INIT_ORDER",
        payload: {
          productDetails: {},
          message: message,
        },
      });
      dispatch({
        type: "SET_CART",
        payload: [...cartItems, ...products],
      });
      toast.success(message, {
        autoClose: 7500,
      });
    }
    let reply = message;
    setResponse(reply);
  };

  const rubyReply = async (rubyResponse) => {
    setResponse(rubyResponse);
    setIsSpeaking(true);
    await updateConversations(text, rubyResponse, senderId);
  };

  async function handleAnythingElseCase(productItems, message) {
    if (productItems.length > 0) {
      orderProcess(productItems, message);
      return;
    }
    const intentAnythingelse = detectAffirmationOrDenial(text);
    if (intentAnythingelse === "affirm") {
      let message = "Great! Please tell me your order.";
      await updateConversations(text, message, senderId);
      dispatch({
        type: "SET_END_ORDER_PROCESS",
        payload: { message },
      });
      setResponse(message);
    } else {
      let message = "Do you want it for pickup or delivery?";
      await updateConversations(text, message, senderId);
      dispatch({
        type: "SELECT_SERVICE_OPTIONS",
        payload: {
          message,
        },
      });
      setResponse(message);
    }
  }

  const handleOrderProcessing = async () => {
    const message = `Prev Details: ${JSON.stringify(checkoutDetails)}: ${text}`;
    const res = await getServiceOptionDetails(message);
    const dateStr =
      res.serviceOptionDetails?.date === "invalid"
        ? 0
        : res.serviceOptionDetails?.date;
    const timeStr =
      res.serviceOptionDetails?.time === "invalid"
        ? 0
        : res.serviceOptionDetails?.time;

    // Parse the date part
    const parsedDate = moment(dateStr, "MMMM D, YYYY");

    // Parse the time part separately
    const parsedTime = moment(timeStr, "hh:mm A");
    // Combine date and time
    parsedDate.hours(parsedTime.hours());
    parsedDate.minutes(parsedTime.minutes());
    // Convert to GMT/UTC
    const gmtDate = moment(
      moment(dateStr).format("MMMM DD, YYYY") +
        " " +
        parsedTime.format("hh:mm A")
    );
    const timeAndDate = moment(
      moment(dateStr).format("MMMM DD, YYYY") + " " + timeStr
    );

    const newCheckoutDetails = updateStateWithValidValues(
      res?.serviceOptionDetails,
      checkoutDetails
    );
    if (newCheckoutDetails?.availableTimes?.length === 0) {
      const availableType =
        newCheckoutDetails?.type === "pick up" ? "delivery" : "pick up";
      const noAvailableTimeMessage = `No available time for ${newCheckoutDetails?.type}, do you want to change it to ${availableType} instead? or do
      you want to cancel your order?`;
      await updateConversations(
        text,
        noAvailableTimeMessage ?? "Something went wrong with our AI service",
        senderId
      );

      setAvailableTimes(newCheckoutDetails?.availableTimes ?? []);
      setResponse(noAvailableTimeMessage);
      return;
    }
    setCheckoutDetails(newCheckoutDetails);
    if (Object.values(newCheckoutDetails).every((value) => value !== "")) {
      dispatch(
        setSelectedServiceOption(newCheckoutDetails?.type === "pick up" ? 1 : 2)
      );
      if (newCheckoutDetails?.time !== "invalid") {
        await updateConversations(
          text,
          "Thank you for ordering. You will now be redirected to check." ??
            "Something went wrong with our AI service",
          senderId
        );

        dispatch(setOrderTimeSelectValue(newCheckoutDetails?.time));
        dispatch(setOrderProcessingDate(timeAndDate));
        dispatch({
          type: "SET_END_ORDER_PROCESS",
          payload: {
            message:
              "Thank you for ordering. You will now be redirected to check." ??
              "Something went wrong with our AI service",
          },
        });

        setAvailableTimes([]);
        setGoToCheck(true);
        setResponse(
          "Thank you for ordering. You will now be redirected to check." ??
            "Something went wrong with our AI service"
        );
      } else {
        await updateConversations(
          text,
          res?.message ?? "Something went wrong with our AI service",
          senderId
        );
        setAvailableTimes(newCheckoutDetails?.availableTimes ?? []);
        setResponse(res?.message ?? "Something went wrong with our AI service");
      }
    } else {
      await updateConversations(
        text,
        res?.message ?? "Something went wrong with our AI service",
        senderId
      );
      setAvailableTimes(res?.serviceOptionDetails?.availableTimes ?? []);
      setResponse(res?.message ?? "Something went wrong with our AI service");
      dispatch({
        type: "SET_PRE_CANCEL_ORDER",
        message: res?.message ?? "Something went wrong with our AI service",
      });
    }

    return;
  };

  const getServiceOptionDetails = async (message) => {
    try {
      const { data } = await axios.post(
        aiBiAPI + "/checkout",
        {
          message: message,
          senderID: senderId,
          siteID: siteId,
          revenueCenter: revenueCenter,
          menuName: menuName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );

      return data;
    } catch (error) {
      return {
        items: [],
        message: "Something went wrong with our completion service",
      };
    }
  };

  const processModiers = async (items) => {
    dispatch({
      type: SET_PRODUCT_WITH_MODIFIERS,
      payload: items,
    });

    setOpenModifierDialog(true);
  };

  const askRuby = useCallback(async () => {
    if (!text || isSpeaking || status === "processing") return;
    setStatus("processing");

    const isCancelled = datectCancel(text);
    if (isCancelled) {
      console.log(isCancelled, "is Cancelled");

      await updateConversations(
        text,
        `Your order has been cancelled, try to order again.`,
        senderId
      );
      dispatch({
        type: "SET_CART",
        payload: [],
      });
      dispatch({
        type: "SET_CANCEL_ORDER",
        message: `Your order has been cancelled, try to order again.`,
      });
      setResponse(`Your order has been cancelled, try to order again.`);
      return;
    }

    // Enhance logic
    if (orderStatus === "service-options") {
      await handleOrderProcessing();
      return;
    }

    const { message, items } = await fetchBIAPI(
      availableProducts?.filter(
        (item) =>
          item?.MenuName === menuName || item?.RevenueCenter === revenueCenter
      ),
      text
    );
    let newItems = items;
    if (newItems.length > 0) {
      newItems = await processItemsWithModifiers(newItems);
      // check if one of the items has modifiers
      const hasModifiers = newItems.some((item) => item.modifiers.length > 0);
      if (hasModifiers) {
        return await processModiers(newItems);
      }
    }
    if (!items.length > 0 && orderStatus === null) {
      return await rubyReply(message);
    }

    switch (orderStatus) {
      case null:
        if (items.length > 0) {
          // await orderProcess(productItem);
          await orderProcess(items, message);
        } else {
          await rubyReply(message);
        }
        break;

      case "order":
        // Handle 'anything else' case with a simplified logic
        await handleAnythingElseCase(items, message);
        break;
      case "service-options":
        // Handle 'anything else' case with a simplified logic
        await handleOrderProcessing();
        break;
      default:
        break;
    }
  }, [text]);

  useEffect(() => {
    askRuby();
  }, [askRuby]);

  useEffect(() => {
    const playSound = (soundId) => {
      const sound = document.getElementById(soundId);
      if (sound) {
        sound.volume = 0.5;
        sound.play();
      }
    };

    if (status === "on") {
      playSound("audioOn");
    }
  }, [status]);

  useEffect(() => {
    if (goToCheck && status === "on" && !isSpeaking) {
      setTimeout(() => {
        if (enableSL === "0") {
          history.push({
            pathname: "/checkout-sl",
            state: {
              menuName,
            },
          });
        } else {
          history.push({
            pathname: "/checkout-sl",
            state: {
              revenueCenter,
              menuName,
            },
          });
        }
      }, 2000);
    }
  }, [status, isSpeaking, goToCheck, history]);

  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return null;
  }
  return (
    <div
      style={{
        position: "fixed",
        bottom: 88,
        right: 72,
        zIndex: 2,
        background: "white",
        borderRadius: "50%",
        border: "2px solid pink",
        padding: "2px",
        boxShadow: "0 0 16px rgba(255, 0, 46, .24)",
      }}
    >
      {/* MULTIPLE PRODUCT DIALOG */}
      {openModifierDialog && (
        <MultipleProductWithModifiersDialog
          open={openModifierDialog}
          handleClose={() => setOpenModifierDialog(false)}
          handleCheckout={handleCloseMultipleProductWithModifiersDialog}
        />
      )}
      <audio id="audioOn" src={Beep} preload="auto"></audio>
      <Box
        id="ruby-button"
        sx={{
          position: "relative",
        }}
      >
        {status === "off" && (
          <IconButton
            id="ruby-button"
            onClick={() => {
              setResponse(rubyInitMessage);
              setTimeout(() => {
                startContinuousRecognition();
              }, 1000);
            }}
            size="large"
            sx={{
              position: "relative",
              background: "white",
              padding: 0,
            }}
          >
            <div
              style={{
                width: "100px",
                height: "100px",

                padding: 0,
              }}
            >
              <img
                id="analyze-video"
                src={Rose}
                width={150}
                height={150}
                style={{
                  objectFit: "cover",
                  width: "100%", // Fill the container's width
                  height: "100%", // Fill the container's height
                  borderRadius: "50%",
                  zIndex: 2,
                  opacity: 1,
                  transition: "opacity 0.5s ease-in-out 0s",
                  transform: "scale(1)", // A tiny nudge for Safari
                }}
                alt="Ruby Listening"
              />
            </div>
          </IconButton>
        )}

        {status === "idle" && (
          <IconButton
            id="ruby-button"
            onClick={startContinuousRecognition}
            size="large"
            sx={{
              position: "relative",
              background: "white",
              padding: 0,
            }}
          >
            <div
              style={{
                width: "100px",
                height: "100px",

                padding: 0,
              }}
            >
              <video
                id="talk-video"
                src={Rose_idle}
                autoPlay
                playsInline
                loop
                width={150}
                height={150}
                style={{
                  objectFit: "cover",
                  width: "100%", // Fill the container's width
                  height: "100%", // Fill the container's height
                  borderRadius: "50%",
                  zIndex: 2,
                  opacity: 1,
                  transition: "opacity 0.5s ease-in-out 0s",
                  transform: "scale(1)", // A tiny nudge for Safari
                }}
              />
            </div>
          </IconButton>
        )}

        {status === "on" && (
          <IconButton
            id="ruby-button"
            onClick={() => {
              stopContinuousRecognition();
              setResponse(
                "Thank you for chatting with me today. If you have any more questions in the future, don't hesitate to reach out. Have a great day! "
              );
            }}
            size="large"
            sx={{
              position: "relative",
              background: "white",
              padding: 0,
            }}
          >
            <div
              style={{
                width: "100px",
                height: "100px",
                padding: 0,
              }}
            >
              <img
                id="analyze-video"
                src={Ruby_listening}
                width={150}
                height={150}
                style={{
                  objectFit: "cover",
                  width: "100%", // Fill the container's width
                  height: "100%", // Fill the container's height
                  borderRadius: "50%",
                  zIndex: 2,
                  opacity: 1,
                  transition: "opacity 0.5s ease-in-out 0s",
                  transform: "scale(1)", // A tiny nudge for Safari
                }}
                alt="Ruby Listening"
              />
            </div>
          </IconButton>
        )}

        {status === "processing" && (
          <IconButton
            id="ruby-button"
            onClick={stopContinuousRecognition}
            size="large"
            sx={{
              position: "relative",
              background: "white",
              padding: 0,
            }}
          >
            <div
              style={{
                width: "100px",
                height: "100px",

                padding: 0,
              }}
            >
              <img
                id="analyze-video"
                src={Ruby_analying}
                width={150}
                height={150}
                style={{
                  objectFit: "cover",
                  width: "100%", // Fill the container's width
                  height: "100%", // Fill the container's height
                  borderRadius: "50%",
                  zIndex: 2,
                  opacity: 1,
                  transition: "opacity 0.5s ease-in-out 0s",
                  transform: "scale(1)", // A tiny nudge for Safari
                }}
                alt="Ruby Analyzing"
              />
            </div>
          </IconButton>
        )}

        {(readText || text) && (
          <Paper
            elevation={5}
            sx={{
              position: "absolute",
              zIndex: 3,
              top: 0,
              right: "100px",
              background: "white",
              padding: 1,
              borderRadius: " 16px 16px 4px 16px",
              alignSelf: "end",
              width: "60vw",
              maxWidth: "max-content",
              boxSizing: "border-box",
              wordBreak: " break-word",
              whiteSpace: "pre-wrap",
              lineHeight: "110%",
            }}
          >
            <Typography variant="subtitle2">{text ?? readText}</Typography>
          </Paper>
        )}

        {response && (
          <Paper
            elevation={5}
            sx={{
              position: "absolute",
              zIndex: 3,
              bottom: 10,
              right: "100px",
              background: (theme) => theme.palette.primary.main,
              color: "white",
              padding: 1,
              borderRadius: " 16px 4px 16px 16px",
              alignSelf: "end",
              width: "60vw",
              maxWidth: "max-content",
              maxHeight: "200px",
              overflow: "auto",
              boxSizing: "border-box",
              wordBreak: " break-word",
              whiteSpace: "pre-wrap",
              lineHeight: "110%",
            }}
          >
            <Typography variant="subtitle2">{response}</Typography>
          </Paper>
        )}

        {availableTimes.length > 0 && (
          <Paper
            elevation={5}
            sx={{
              position: "absolute",
              zIndex: 3,
              bottom: -50,
              right: "100px",
              background: (theme) => theme.palette.primary.main,
              color: "white",
              padding: 1,
              borderRadius: " 16px 4px 16px 16px",
              alignSelf: "end",
              width: "40vw",
              maxWidth: "max-content",
              maxHeight: "200px",
              overflow: "auto",
              boxSizing: "border-box",
              wordBreak: " break-word",
              whiteSpace: "pre-wrap",
              lineHeight: "110%",
            }}
          >
            <Typography>Please select a time:</Typography>
            <Box flexWrap="wrap" flexDirection="row">
              {availableTimes.map((t) => (
                <Chip
                  style={{
                    margin: 2,
                    background: "white !important",
                  }}
                  label={t}
                  size="small"
                />
              ))}
            </Box>
          </Paper>
        )}
      </Box>
      {/* <IconButton
        id="ruby-button"
        onClick={startRecognition}
        size="large"
        sx={{
          position: "relative",
          background: "white",
          padding: 0,
        }}
      >
        <div
          style={{
            width: "100px",
            height: "100px",
            padding: 0,
            position: "relative",
            objectFit: "cover",
          }}
        >
          <iframe
            title="processing"
            src="https://giphy.com/embed/ucb07i9l4USMHI1mS6"
            width="100%"
            height="100%"
            style={{
              borderRadius: "50%",
            }}
            allowFullScreen
          ></iframe>
        </div>
      </IconButton> */}
    </div>
  );
}

export default NewAISpeech;
